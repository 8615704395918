import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const kickerPlaceholder = "Ein Platzhalter-Kicker"
const headlinePlaceholder = "Eine Platzhalter-Überschrift"
const subheadlinePlaceholder = "Eine Platzhalter-Unterzeile"
const imagePlaceholder = {
  filename:
    "https://a.storyblok.com/f/108683/1920x1080/d6600d2022/bg_placeholder_1080p_center.jpg",
}

const HeroOnlyPage = () => {
  const body = [
    {
      component: "hero_block",
      kicker: kickerPlaceholder,
      headline: "Hero Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image: imagePlaceholder,
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default HeroOnlyPage
